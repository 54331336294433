import { getPdf } from '@/api/pdf'

export default {
  namespaced: true,
  state: {
    pdfId: ''
  },
  mutations: {
    setPdfId (state, payload) {
      state.pdfId = payload
    }
  },
  actions: {
    fetch ({ commit }, { success, fail } = {}) {
      getPdf(response => {
        commit('setPdfId', response.body.data)
        success && success(response)
      }, fail)
    }
  }
}
