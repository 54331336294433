const homeConfig = {
  articleDefaultQueryParams: {
    page: 1,
    itemPerPage: 10,
    search: ''
  }
}
const scheduleConfig = {
  type: {
    public: 'PUBLIC',
    teen: 'TEEN',
    kids: 'KIDS'
  }
}
const commonConfig = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Des'],
  typeYoutube: 'YOUTUBE',
  mainMenu: ['Home', 'Fellowship', 'Schedule']
}

export {
  homeConfig,
  commonConfig,
  scheduleConfig
}
