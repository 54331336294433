export default [
  {
    url: '/backend/gki/banner/_all',
    code: 200,
    method: 'GET',
    response: {
      data: [
        {
          id: '5f4b378ef11ab624b463786b',
          createdBy: null,
          createdDate: '2020-08-30T05:22:22.010+0000',
          updatedBy: null,
          updatedDate: '2020-08-30T05:22:22.010+0000',
          version: 0,
          delete: false,
          title: 'Hidup Bahagia Tanpa Rasa Bersalah',
          imageUrl: 'https://gkigunsa.com/static-image/Sagem361-1621947254400.jpg',
          youtubeVideoId: 'PouQb7AJVSE', // klw type default ini blank
          type: 'YOUTUBE'
        },
        {
          id: '5f41bea3f11ab654e3367dce',
          createdBy: null,
          createdDate: '2020-08-23T00:56:03.005+0000',
          updatedBy: null,
          updatedDate: '2020-08-23T00:56:03.005+0000',
          version: 0,
          delete: false,
          title: 'Renungan Harian 23 Mei 2021',
          imageUrl: 'https://gkigunsa.com/static-image/FOH02-1613174486735.jpg',
          redirectUrl: '/detail/60a895b8f11ab610494a12e3',
          type: 'DEFAULT'
        },
        {
          createdBy: 'boen',
          createdDate: '2021-06-05T00:29:45.320+0000',
          delete: false,
          id: '60bac579f11ab610494a139d',
          imageUrl: 'https://i.ibb.co/brfLgKc/bannerpdp05jun.jpg',
          redirectUrl: '/persekutuan-detail/60a38ac6f11ab610494a12b8',
          title: 'Persekutuan Doa Pagi oleh Pdt. Merry Malau',
          type: 'DEFAULT',
          updatedBy: 'boen',
          updatedDate: '2021-06-05T00:29:45.320+0000',
          version: 0,
          youtubeVideoId: ''
        }
      ],
      pageMetaData: {
        pageSize: 5,
        pageNumber: 1,
        totalRecords: 5
      }
    }
  }
]
