<template>
  <div class="w-full relative z-10 shadow-lg">
    <div class="h-14 bg-blue-300 flex justify-between p-3">
      <img @click="toggleNavDrawer" src="~@/assets/icons/list-white.svg" alt="menu">
      <img @click="share" src="~@/assets/icons/share-network-white.svg" alt="share">
    </div>
    <div v-if="visibleNavDrawer">
      <div class="w-4/6 bg-white h-screen z-20 absolute inset-0 rounded-r-xl p-8">
        <div class="text-xl text-gray-800 font-bold">Menu Lainnya</div>
        <div class="flex-col space-y-4 my-4">
          <div
            @click="redirectTo('Pdf')"
            :class="['flex items-center space-x-2 cursor-pointer', {'btn': isPdf, 'btn-outline': !isPdf}]">
            <img v-if="isPdf" class="w-8" src="~@/assets/icons/menu/pdf-white.svg" alt="house">
            <img v-else class="w-8" src="~@/assets/icons/menu/pdf-blue.svg" alt="house">
            <div>Warta</div>
          </div>
          <div
            @click="redirectTo('Offering')"
            :class="['flex items-center space-x-2 cursor-pointer', {'btn': isOffering, 'btn-outline': !isOffering}]">
            <img v-if="isOffering" class="w-8" src="~@/assets/icons/menu/money-white.svg" alt="house">
            <img v-else class="w-8" src="~@/assets/icons/menu/money-blue.svg" alt="house">
            <div>Persembahan</div>
          </div>
        </div>
      </div>
      <div @click="toggleNavDrawer" class="mask w-full h-screen absolute masking z-10 inset-0"></div>
    </div>
  </div>
</template>

<script>
import { share } from '@/utils/share'
import location from '@/mixins/location'
export default {
  name: 'AppBar',
  mixins: [location],
  data () {
    return {
      visibleNavDrawer: false
    }
  },
  methods: {
    share,
    toggleNavDrawer () {
      this.visibleNavDrawer = !this.visibleNavDrawer
    },
    redirectTo (name) {
      this.$router.push({
        name
      })
      this.toggleNavDrawer()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
