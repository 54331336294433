import { createStore } from 'vuex'
import banner from './modules/banner'
import article from './modules/article'
import fellowship from './modules/fellowship'
import event from './modules/event'
import pdf from './modules/pdf'

export default createStore({
  modules: {
    banner,
    article,
    fellowship,
    event,
    pdf
  }
})
