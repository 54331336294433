<template>
  <div class="home">
    <Banner />
    <Article />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
const Banner = defineAsyncComponent(() => import('@/components/home/Banner.vue'))
const Article = defineAsyncComponent(() => import('@/components/home/Article.vue'))
export default {
  name: 'Home',
  components: {
    Banner,
    Article
  }
}
</script>
