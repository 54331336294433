<template>
  <div class="app mx-auto h-screen bg-gray-100">
    <AppBar v-if="isHome"/>
    <AppHeader v-else/>
    <div class="p-4">
      <router-view/>
    </div>
    <div class="h-16 bg-gray-100"></div>
    <BottomNavigation/>
  </div>
</template>

<style lang="scss" scoped>
.app {
  max-width: 600px !important;
}
</style>
<script>
import AppBar from '@/components/common/AppBar.vue'
import AppHeader from '@/components/common/AppHeader.vue'
import BottomNavigation from '@/components/common/BottomNavigation.vue'
import { commonConfig } from '@/config'

export default {
  name: 'App',
  components: {
    AppBar,
    BottomNavigation,
    AppHeader
  },
  computed: {
    currentPage () {
      return this.$route.name
    },
    isHome () {
      return commonConfig.mainMenu.includes(this.currentPage)
    }
  }
}
</script>
