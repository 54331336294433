<template>
  <div class="h-14 bg-blue-300 flex justify-between p-3">
    <img
      class="cursor-pointer"
      @click="goBack"
      src="~@/assets/icons/arrow-left-white.svg" alt="back">
    <img @click="shareApp" src="~@/assets/icons/share-network-white.svg" alt="share">
  </div>
</template>

<script>
import { share } from '@/utils/share'
import location from '@/mixins/location'
import { mapState } from 'vuex'
export default {
  name: 'AppHeader',
  mixins: [location],
  computed: {
    ...mapState('article', ['detail']),
    ...mapState('fellowship', {
      detailFellowship: 'detail'
    }),
    detailArticleShareData () {
      return {
        title: this.detail.title,
        text: this.detail.summary,
        url: `https://gkigunsa.com/detail/${this.detail.id}`,
        image: this.detail.imageUrl
      }
    },
    detailFellowshipShareData () {
      return {
        title: this.detailFellowship.title,
        text: this.detailFellowship.summary,
        url: `https://gkigunsa.com/persekutuan/${this.detailFellowship.id}`,
        image: this.detailFellowship.imageUrl
      }
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    shareApp () {
      if (this.isDetailArticle) share(this.detailArticleShareData)
      else if (this.isDetailFellowship) share(this.detailFellowshipShareData)
      else share()
    }
  }
}
</script>

<style scoped>

</style>
