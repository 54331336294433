const projectNamespace = '/backend/gki'
const bannerNamespace = `${projectNamespace}/banner`
const articleNamespace = `${projectNamespace}/article`
const fellowshipNamespace = `${projectNamespace}/donation`
const eventNamespace = `${projectNamespace}/events`
const pdfNamespace = `${projectNamespace}/files`

export default {
  banner: {
    all: `${bannerNamespace}/_all`
  },
  article: {
    all: `${articleNamespace}/_all`,
    detail: id => `${articleNamespace}/${id}`
  },
  fellowship: {
    all: `${fellowshipNamespace}/_all`,
    detail: id => `${fellowshipNamespace}/${id}`
  },
  event: {
    all: `${eventNamespace}/_all`
  },
  pdf: {
    location: `${pdfNamespace}/location`
  }
}
