export default {
  computed: {
    currentPage () {
      return this.$route.name
    },
    isHome () {
      return this.currentPage === 'Home'
    },
    isFellowShip () {
      return this.currentPage === 'Fellowship'
    },
    isSchedule () {
      return this.currentPage === 'Schedule'
    },
    isOffering () {
      return this.currentPage === 'Offering'
    },
    isPdf () {
      return this.currentPage === 'Pdf'
    },
    isDetailArticle () {
      return this.currentPage === 'DetailArticle'
    },
    isDetailFellowship () {
      return this.currentPage === 'DetailFellowship'
    }
  }
}
