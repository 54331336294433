export default [
  {
    url: '/backend/gki/donation/_all',
    method: 'GET',
    code: 200,
    response: {
      data: [
        {
          id: '60b36118f11ab610494a1360',
          title: 'Pemahaman Alkitab',
          summary: 'Pemahaman Alkitab GKI Gunsa',
          date: 1622635200000,
          imageUrl: 'https://i.ibb.co/3v95fKQ/PA-02Jun.jpg',
          information: {
            commission: 'Bidang Bina',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/84325638943?pwd=ZVFqeFFHZWZTaHlJWWNLL2pzODRDdz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-30T09:55:36.888+0000',
          updatedDate: '2021-05-31T03:55:31.905+0000'
        },
        {
          id: '60a6f689f11ab610494a12ce',
          title: 'PD Komisi Senior',
          summary: 'Persekutuan Doa KS GKI Gunsa',
          date: 1622775600000,
          imageUrl: 'https://i.ibb.co/qjcnJVn/pdks04jun.jpg',
          information: {
            commission: 'Senior',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/83564476494?pwd=eVVqTUdCT1BZQ1hVWGtNUlhMeHRCUT09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-20T23:53:45.338+0000',
          updatedDate: '2021-05-20T23:53:45.340+0000'
        },
        {
          id: '60a278eaf11ab610494a12a0',
          title: 'PD Komisi Dewasa ',
          summary: 'Persekutuan Doa Komisi Dewasa GKI Gunsa',
          date: 1622786400000,
          imageUrl: 'https://i.ibb.co/VjBdjZN/PDKD04-Jun.jpg',
          information: {
            commission: 'Dewasa',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/88207888821?pwd=eVJxY2p4c0czZXVoZ215OUFMdThsUT09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-17T14:08:42.945+0000',
          updatedDate: '2021-05-17T14:08:42.947+0000'
        },
        {
          id: '60a38ac6f11ab610494a12b8',
          title: 'Persekutuan Doa Pagi',
          summary: 'Persekutuan Doa Pagi GKI Gunsa',
          date: 1622854800000,
          imageUrl: 'https://i.ibb.co/JqYyvyN/PDP05Jun.jpg',
          information: {
            commission: 'Bidang PO',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/82339451983?pwd=c1R3SzZ4RFNOaW9vVEUzOUYrZzlQZz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-18T09:37:10.457+0000',
          updatedDate: '2021-05-18T09:37:10.460+0000'
        },
        {
          id: '60a73934f11ab610494a12d1',
          title: 'PD Talikum',
          summary: 'Persekutuan Doa Talikum GKI Gunsa',
          date: 1623207600000,
          imageUrl: 'https://i.ibb.co/r5YN6Sz/PDTalikum09-Jun.jpg',
          information: {
            commission: 'Senior',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/88472240897?pwd=MEJ4a0hJdk8xS3RXaUZyVzB4dXVxQT09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-21T04:38:12.335+0000',
          updatedDate: '2021-05-21T04:47:43.894+0000'
        },
        {
          id: '60b36c1df11ab610494a1361',
          title: 'Pemahaman Alkitab  ',
          summary: 'Pemahaman Alkitab GKI Gunsa',
          date: 1623240000000,
          imageUrl: 'https://i.ibb.co/7vf0ggc/PA-09Jun.jpg',
          information: {
            commission: 'Bidang Bina',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/84325638943?pwd=ZVFqeFFHZWZTaHlJWWNLL2pzODRDdz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-30T10:42:37.385+0000',
          updatedDate: '2021-05-30T10:42:37.391+0000'
        },
        {
          id: '60a279d7f11ab610494a12a1',
          title: 'PD Komisi Dewasa ',
          summary: 'Persekutuan Doa Komisi Dewasa GKI Gunsa',
          date: 1623391200000,
          imageUrl: 'https://i.ibb.co/7C2dgXM/PDKD11-Jun.jpg',
          information: {
            commission: 'Dewasa',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/88207888821?pwd=eVJxY2p4c0czZXVoZ215OUFMdThsUT09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-17T14:12:39.587+0000',
          updatedDate: '2021-05-17T14:14:47.373+0000'
        },
        {
          id: '60a38b96f11ab610494a12b9',
          title: 'Persekutuan Doa Pagi',
          summary: 'Persekutuan Doa Pagi GKI Gunsa',
          date: 1623459600000,
          imageUrl: 'https://i.ibb.co/8NKYgzg/PDP12Jun.jpg',
          information: {
            commission: 'Bidang PO',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/82339451983?pwd=c1R3SzZ4RFNOaW9vVEUzOUYrZzlQZz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-18T09:40:38.648+0000',
          updatedDate: '2021-05-18T09:46:10.408+0000'
        },
        {
          id: '60b36cfaf11ab610494a1362',
          title: 'Pemahaman Alkitab  ',
          summary: 'Pemahaman Alkitab GKI Gunsa',
          date: 1623844800000,
          imageUrl: 'https://i.ibb.co/jyX40Z1/PA-16Jun.jpg',
          information: {
            commission: 'Bidang Bina',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/84325638943?pwd=ZVFqeFFHZWZTaHlJWWNLL2pzODRDdz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-30T10:46:18.442+0000',
          updatedDate: '2021-05-30T10:46:18.445+0000'
        },
        {
          id: '60a27b12f11ab610494a12a2',
          title: 'PD Komisi Dewasa ',
          summary: 'Persekutuan Doa Komisi Dewasa GKI Gunsa',
          date: 1623996000000,
          imageUrl: 'https://i.ibb.co/GpsmZgk/PDKD18-Jun.jpg',
          information: {
            commission: 'Dewasa',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/88207888821?pwd=eVJxY2p4c0czZXVoZ215OUFMdThsUT09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-17T14:17:54.679+0000',
          updatedDate: '2021-05-17T14:17:54.681+0000'
        },
        {
          id: '60a38c43f11ab610494a12ba',
          title: 'Persekutuan Doa Pagi',
          summary: 'Persekutuan Doa Pagi GKI Gunsa',
          date: 1624064400000,
          imageUrl: 'https://i.ibb.co/QC4BZch/PDP19Jun.jpg',
          information: {
            commission: 'Bidang PO',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/82339451983?pwd=c1R3SzZ4RFNOaW9vVEUzOUYrZzlQZz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-18T09:43:31.662+0000',
          updatedDate: '2021-05-18T09:43:31.665+0000'
        },
        {
          id: '60962153f11ab63dd795eeb8',
          title: 'Komisi Anak (SIL)',
          summary: 'Sekolah Injil Liburan 2021',
          date: 1624066200000,
          imageUrl: 'https://i.ibb.co/VTRXBZt/SIL-01.jpg',
          information: {
            commission: 'Anak',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: '',
            serviceValue: ''
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-08T05:27:47.437+0000',
          updatedDate: '2021-05-08T05:27:47.438+0000'
        },
        {
          id: '60a6f728f11ab610494a12cf',
          title: 'PD Komisi Senior',
          summary: 'Persekutuan Doa KS GKI Gunsa',
          date: 1624071600000,
          imageUrl: 'https://i.ibb.co/HYz31FM/PDKS19-Jun.jpg',
          information: {
            commission: 'Senior',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/83564476494?pwd=eVVqTUdCT1BZQ1hVWGtNUlhMeHRCUT09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-20T23:56:24.563+0000',
          updatedDate: '2021-05-23T12:46:24.752+0000'
        },
        {
          id: '60b36dadf11ab610494a1363',
          title: 'Pemahaman Alkitab  ',
          summary: 'Pemahaman Alkitab GKI Gunsa',
          date: 1624449600000,
          imageUrl: 'https://i.ibb.co/x8yDQmp/PA-23Jun.jpg',
          information: {
            commission: 'Bidang Bina',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/84325638943?pwd=ZVFqeFFHZWZTaHlJWWNLL2pzODRDdz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-30T10:49:17.966+0000',
          updatedDate: '2021-05-30T10:49:17.969+0000'
        },
        {
          id: '60a27bb2f11ab610494a12a3',
          title: 'PD Komisi Dewasa ',
          summary: 'Persekutuan Doa Komisi Dewasa GKI Gunsa',
          date: 1624600800000,
          imageUrl: 'https://i.ibb.co/rtM5L7g/PDKD25-Jun.jpg',
          information: {
            commission: 'Dewasa',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/88207888821?pwd=eVJxY2p4c0czZXVoZ215OUFMdThsUT09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-17T14:20:34.540+0000',
          updatedDate: '2021-05-17T14:20:34.542+0000'
        },
        {
          id: '60a38ca3f11ab610494a12bb',
          title: 'Persekutuan Doa Pagi',
          summary: 'Persekutuan Doa Pagi GKI Gunsa',
          date: 1624669200000,
          imageUrl: 'https://i.ibb.co/ws8442b/PDP26Jun.jpg',
          information: {
            commission: 'Bidang PO',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/82339451983?pwd=c1R3SzZ4RFNOaW9vVEUzOUYrZzlQZz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-18T09:45:07.736+0000',
          updatedDate: '2021-05-18T09:45:07.739+0000'
        },
        {
          id: '60b36e2cf11ab610494a1364',
          title: 'Pemahaman Alkitab  ',
          summary: 'Pemahaman Alkitab GKI Gunsa',
          date: 1625054400000,
          imageUrl: 'https://i.ibb.co/98y3Tfb/PA-30Jun.jpg',
          information: {
            commission: 'Bidang Bina',
            dateInformation: '',
            meetingId: '',
            password: '',
            time: '',
            serviceType: 'Zoom',
            serviceValue: 'https://us02web.zoom.us/j/84325638943?pwd=ZVFqeFFHZWZTaHlJWWNLL2pzODRDdz09'
          },
          createdBy: 'boen',
          updatedBy: 'boen',
          createdDate: '2021-05-30T10:51:24.796+0000',
          updatedDate: '2021-05-30T10:51:24.798+0000'
        }
      ],
      pageMetaData: {
        pageSize: 17,
        pageNumber: 1,
        totalRecords: 17
      }
    }
  },
  {
    url: '/backend/gki/donation/60b36118f11ab610494a1360',
    method: 'GET',
    code: 200,
    response: {
      data: {
        id: '60b36118f11ab610494a1360',
        createdBy: 'boen',
        createdDate: '2021-05-30T09:55:36.888+0000',
        updatedBy: 'boen',
        updatedDate: '2021-05-31T03:55:31.905+0000',
        version: 3,
        delete: false,
        title: 'Pemahaman Alkitab',
        imageUrl: 'https://i.ibb.co/3v95fKQ/PA-02Jun.jpg',
        summary: 'Pemahaman Alkitab GKI Gunsa',
        contents: '<b>“ Berkat –Berkat-Berkat  !!”</b>\n<br/><i> Bilangan 6:22-27)</i>\n<br/><br/> Kebaktian selalu diakhiri berkat. Dan itu salah satu yang kita tunggu bukan ?  Rumusan berkat yang sering kita dengar diakhir kebaktian adalah Bilangan 6:22-27.<br/><b>”TUHAN menghadapkan wajah-Nya kepadamu… “</b> Inilah salah satunya. <br/>Apa maknanya ? Mari kita gali bersama dalam PA, sehingga kita semakin merasa diberkati TUHAN dan Waowww ! \n',
        date: 1622635200000,
        information: {
          commission: 'Bidang Bina',
          dateInformation: '',
          meetingId: '',
          password: '',
          time: '',
          serviceType: 'Zoom',
          serviceValue: 'https://us02web.zoom.us/j/84325638943?pwd=ZVFqeFFHZWZTaHlJWWNLL2pzODRDdz09'
        }
      }
    }
  }
]
