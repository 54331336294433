export default [
  {
    url: '/backend/gki/files/location',
    method: 'GET',
    code: 200,
    response: {
      data: '1MRhnNNaWdI1iYQ9vQrAqSBtcmyuFe8le'
    }
  }
]
