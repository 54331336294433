export default [
  {
    url: '/backend/gki/events/_all',
    method: 'GET',
    code: 200,
    response: {
      data: [
        {
          id: '60abab35f11ab610494a1312',
          createdBy: 'Glen',
          createdDate: '2021-05-24T13:33:41.102+0000',
          updatedBy: 'boen',
          updatedDate: '2021-05-29T15:47:44.900+0000',
          version: 2,
          delete: false,
          theme: 'Manusia Ciptaan Terindah (Ulangan)',
          pastorName: '',
          pastorAssistantName: '',
          greeterNames: '',
          multimediaName: '',
          choirName: '',
          chantingGuideName: '',
          bandName: '',
          className: 'Batita',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'KIDS',
          eventDate: 1622336400000,
          youtubeVideoId: '',
          serviceType: 'Youtube',
          serviceValue: '/player?v=BquBSS1sBic'
        },
        {
          id: '60abab68f11ab610494a1313',
          createdBy: 'Glen',
          createdDate: '2021-05-24T13:34:32.560+0000',
          updatedBy: 'Glen',
          updatedDate: '2021-05-28T08:00:07.004+0000',
          version: 2,
          delete: false,
          theme: 'Seorang Mentri Keuangan Etiopia (Kisah Para Rasul 8:26-39)',
          pastorName: '',
          pastorAssistantName: '',
          greeterNames: '',
          multimediaName: '',
          choirName: '',
          chantingGuideName: '',
          bandName: '',
          className: 'Balita',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'KIDS',
          eventDate: 1622336400000,
          youtubeVideoId: '',
          serviceType: 'YOUTUBE',
          serviceValue: '/player?v=WfTlsmqD8uk'
        },
        {
          id: '60abab96f11ab610494a1314',
          createdBy: 'Glen',
          createdDate: '2021-05-24T13:35:18.524+0000',
          updatedBy: 'Glen',
          updatedDate: '2021-05-28T08:00:26.598+0000',
          version: 2,
          delete: false,
          theme: 'Petrus Melayani di Lida dan Yope (Kisah Para Rasul 9:32-43)',
          pastorName: '',
          pastorAssistantName: '',
          greeterNames: '',
          multimediaName: '',
          choirName: '',
          chantingGuideName: '',
          bandName: '',
          className: 'Kecil',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'KIDS',
          eventDate: 1622336400000,
          youtubeVideoId: '',
          serviceType: 'YOUTUBE',
          serviceValue: '/player?v=MKMmT5ogn08'
        },
        {
          id: '60ababc8f11ab610494a1315',
          createdBy: 'Glen',
          createdDate: '2021-05-24T13:36:08.778+0000',
          updatedBy: 'boen',
          updatedDate: '2021-05-29T15:48:18.076+0000',
          version: 2,
          delete: false,
          theme: 'Romo Mangun Wijaya : Percaya Yesus, Mengasihi Sesama (1 Yohanes 3:18,23)',
          pastorName: '',
          pastorAssistantName: '',
          greeterNames: '',
          multimediaName: '',
          choirName: '',
          chantingGuideName: '',
          bandName: '',
          className: 'Besar',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'KIDS',
          eventDate: 1622336400000,
          youtubeVideoId: '',
          serviceType: 'Youtube',
          serviceValue: '/player?v=sTbvLZUrt6E'
        },
        {
          id: '60ababf5f11ab610494a1316',
          createdBy: 'Glen',
          createdDate: '2021-05-24T13:36:53.432+0000',
          updatedBy: 'Glen',
          updatedDate: '2021-05-28T07:59:35.411+0000',
          version: 3,
          delete: false,
          theme: 'Grow in Cell Group (Pra Kamp III)',
          pastorName: '',
          pastorAssistantName: '',
          greeterNames: '',
          multimediaName: '',
          choirName: '',
          chantingGuideName: '',
          bandName: '',
          className: 'Tunas',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'KIDS',
          eventDate: 1622336400000,
          youtubeVideoId: '',
          serviceType: 'YOUTUBE',
          serviceValue: '/player?v=iH1lDs4B-aE'
        },
        {
          id: '60b6162df11ab610494a1373',
          createdBy: 'Glen',
          createdDate: '2021-06-01T11:12:45.341+0000',
          updatedBy: 'Glen',
          updatedDate: '2021-06-01T11:12:45.341+0000',
          version: 0,
          delete: false,
          theme: '"Gereja Pasca Pandemi: On-line atau On-site ?"',
          pastorName: 'Pdt. Handi Hadiwitanto, Ph.D',
          pastorAssistantName: 'Pnt. Lanny',
          greeterNames: 'Pnt. Purwanto',
          multimediaName: '-',
          choirName: '-',
          chantingGuideName: 'Jesica, Naomi, Selvy Arisandy',
          bandName: 'Yesaya',
          className: '',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'PUBLIC',
          eventDate: 1622934000000,
          youtubeVideoId: '',
          serviceType: '',
          serviceValue: ''
        },
        {
          id: '60b616f3f11ab610494a1374',
          createdBy: 'Glen',
          createdDate: '2021-06-01T11:16:03.262+0000',
          updatedBy: 'Glen',
          updatedDate: '2021-06-01T11:16:03.262+0000',
          version: 0,
          delete: false,
          theme: '"Gereja Pasca Pandemi: On-line atau On-site ?"',
          pastorName: 'Pdt. Handi Hadiwitanto, Ph.D',
          pastorAssistantName: 'Pnt. Lanny',
          greeterNames: 'Pnt. Purwanto',
          multimediaName: '-',
          choirName: '-',
          chantingGuideName: 'Jesica, Naomi, Selvy Arisandy',
          bandName: 'Yesaya',
          className: '',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'PUBLIC',
          eventDate: 1622941200000,
          youtubeVideoId: '',
          serviceType: '',
          serviceValue: ''
        },
        {
          id: '60b61747f11ab610494a1375',
          createdBy: 'Glen',
          createdDate: '2021-06-01T11:17:27.236+0000',
          updatedBy: 'Glen',
          updatedDate: '2021-06-01T11:17:27.236+0000',
          version: 0,
          delete: false,
          theme: '"Gereja Pasca Pandemi: On-line atau On-site ?"',
          pastorName: 'Pdt. Handi Hadiwitanto, Ph.D',
          pastorAssistantName: 'Pnt. Lanny',
          greeterNames: 'Pnt. Purwanto',
          multimediaName: '-',
          choirName: '-',
          chantingGuideName: 'Jesica, Naomi, Selvy Arisandy',
          bandName: 'Yesaya',
          className: '',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'PUBLIC',
          eventDate: 1622950200000,
          youtubeVideoId: '',
          serviceType: '',
          serviceValue: ''
        },
        {
          id: '60b61794f11ab610494a1376',
          createdBy: 'Glen',
          createdDate: '2021-06-01T11:18:44.565+0000',
          updatedBy: 'Glen',
          updatedDate: '2021-06-01T11:18:44.565+0000',
          version: 0,
          delete: false,
          theme: '"Gereja Pasca Pandemi: On-line atau On-site ?"',
          pastorName: 'Pdt. Handi Hadiwitanto, Ph.D',
          pastorAssistantName: 'Pnt. Lanny',
          greeterNames: 'Pnt. Purwanto',
          multimediaName: '-',
          choirName: '-',
          chantingGuideName: 'Jesica, Naomi, Selvy Arisandy',
          bandName: 'Yesaya',
          className: '',
          liturgosName: '',
          singerName: '',
          info: '',
          type: 'PUBLIC',
          eventDate: 1622973600000,
          youtubeVideoId: '',
          serviceType: '',
          serviceValue: ''
        },
        {
          id: '5e033f829654634606370a24bda065',
          theme: 'The Sacraments of Holy Baptism',
          pastorName: 'Pdt. Sura Prawira',
          pastorAssistantName: 'Pnt. Felicia Irene Atmadja',
          bandName: 'MATTHEW K & FRIENDS',
          liturgosName: 'Gabriela N.',
          singerName: 'Fanya / Jesseline S',
          info: 'KEBAKTIAN REMAJA',
          type: 'TEEN',
          eventDate: 1578186000000,
          serviceType: 'Youtube',
          serviceValue: '/player?v=oFHONLjD4MM'
        }
      ],
      pageMetaData: {
        pageSize: 9,
        pageNumber: 1,
        totalRecords: 9
      }
    }
  }
]
