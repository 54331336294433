import { get } from '@/utils/http-api'
import api from '@/config/api'

const getPdf = (success, fail) => {
  get(api.pdf.location, success, fail)
}

export {
  getPdf
}
